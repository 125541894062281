<template>
    <LeaveOfAbsence clientCode="CARNIVAL" :frontPageRightContent="frontPageRightContent"
          :introPageRightContent="introPageRightContent"
          :backText="backText"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/Carnival/variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "CMELeaveOfAbsence",
   data() {
    return {
      frontPageRightContent: { 
        img: {
          configuration: {
            src: "/Carnival/Images/georg-eiermann-MvlePq1ZQvU-unsplash_420px.png",
            isLocalPath: false
          }
        }
      },
      introPageRightContent: { 
        img: {
          configuration: {
            src: "/Carnival/Images/samule-sun-vU2YJdNr8O8-unsplash_500px.png",
            isLocalPath: false
          }
        }
      },
      backText: '<i class="fas fa-chevron-left"></i>&nbsp;Back'
    }
  },
};
</script>
<style lang="scss" scoped src="@/assets/css/client/loa/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/feedback_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/send_email_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/style.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/Carnival/style.scss"></style>
